import React from 'react'
import phone from '../assets/icons/phone.svg'
import ymaps from 'ymaps';
import { useTranslation } from 'react-i18next';

const Branches = () => {

    const {t} = useTranslation()

    ymaps
        .load("https://api-maps.yandex.ru/2.1/?apikey=3ccce595-960c-4919-ad3d-65478a41ba4a&lang=ru_RU")
        .then(maps => {
            let map = new maps.Map('map1', {
                center: [43.236232, 76.850922],
                zoom: 18
            });

            map.geoObjects.add(new maps.Placemark([43.236232, 76.850922], {
                    balloonContent: '<strong>MGM Stan</strong>'
                }, {
                    preset: 'islands#icon',
                    iconColor: '#0095b6'
                })
            )

            let mapShymkent = new maps.Map("map-shymkent", {
                center: [42.312324, 69.623523],
                zoom: 18
            })

            mapShymkent.geoObjects.add(new maps.Placemark([42.312324, 69.623523], {
                balloonContent: '<strong>MGM Stan</strong>'
            }, {
                preset: 'islands#icon',
                iconColor: '#0095b6'
            })
        )
        })
    .catch(error => console.log('Failed to load Yandex Maps', error));

    return (
        <div className='container mx-auto mt-16'>
            <div className='flex flex-col space-y-16'>
                <div className='flex justify-center text-3xl font-bold text-darkBlue'>{t('branches.title')}</div>
                <div className='grid grid-cols-1 md:grid-cols-2 gap-8'>
                    <div className='flex flex-col'>
                        <div id='map1' className='w-full h-[400px]'></div>
                        <div className='text-darkBlue text-xl font-semibold py-4 mt-4 border-b-[0.5px] border-darkBlue'>Алматы</div>
                        <div className='flex items-start justify-between py-4'>
                            <p className='text-xl max-w-[200px]'>
                             {t('branches.almaty')}
                            </p>
                            <div className='flex items-center space-x-2'>
                                <img src={phone} width={20}/>
                                <span className='md:text-xl'>+7 727 355 5000</span>
                            </div>
                        </div>
                    </div>
                    <div className='flex flex-col'>
                        <div id='map-shymkent' className='w-full h-[400px]'></div>
                        <div className='text-darkBlue text-xl font-semibold py-4 mt-4 border-b-[0.5px] border-darkBlue'>Шымкент</div>
                        <div className='flex items-start justify-between py-4'>
                            <p className='text-xl max-w-[250px]'>
                             {t('branches.shymkent')}
                            </p>
                            <div className='flex items-center space-x-2'>
                                <img src={phone} width={20}/>
                                <span className='md:text-xl'>+7 775 446 22 23</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Branches