import { BrowserRouter, Routes, Route } from "react-router-dom";
import Layout from "./components/Layout";
import Home from "./pages/Home";
import Team from "./pages/Team";
import ScrollToTop from "./components/ScrollTop";
import Services from "./pages/Services";
import About from "./pages/About";
import Contact from "./pages/Contact";
import Branches from "./pages/Branches";

function App() {
  return (
    <BrowserRouter>
      <ScrollToTop/>
      <Routes>
        <Route path="/" element={<Layout/>}>
          <Route index element={<Home/>} />
          <Route path="team" element={<Team/>} />
          <Route path="services" element={<Services/>} />
          <Route path="about" element={<About/>} />
          <Route path="contact" element={<Contact/>} />
          <Route path="branches" element={<Branches/>} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
