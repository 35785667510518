import React from 'react'
import logo from '../assets/imgs/logo.png'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

const Footer = () => {
    const {t} = useTranslation()

    return (
      <footer className='container mx-auto pt-10 pb-10 lg:pb-16 mt-auto'>
        <div className='flex flex-wrap items-start lg:items-center justify-between gap-4'>
          <img className='order-1' src={logo} width={150} />
          <div className='order-3 sm:order-2 flex flex-col lg:flex-row lg:items-center space-y-2 lg:space-y-0 lg:space-x-10'>
            <Link to={"/about"} className='font-medium'>{t('navbar.about')}</Link>
            <Link to={"/team"} className='font-medium'>{t('navbar.team')}</Link>
            <Link to={"/services"} className='font-medium'>{t('navbar.services')}</Link>
            <Link to={"/contact"} className='font-medium'>{t('navbar.contact')}</Link>
            <Link to={"/branches"} className='font-medium'>{t('navbar.branches')}</Link>
          </div>
          <div className='order-2 sm:order-3 flex flex-col space-y-2 lg:text-right'>
            <a href='tel:+77783555000' className='font-semibold '>+7 (778) 355-50-00</a>
            <p className='text-gray-700 text-sm max-w-[150px] md:max-w-[400px]'>{t('navbar.address')}</p>
          </div>
        </div>
      </footer>
    )
}

export default Footer