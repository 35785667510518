import React from 'react'
import Navbar from './Navbar'
import {Outlet} from 'react-router-dom'
import Footer from './Footer'

const Layout = () => {
  return (
    <div className='flex flex-col h-[100vh]'>
        <Navbar/>
        <div className='mt-20 md:mt-40'>
          <Outlet/>
        </div>
        <Footer/>
    </div>
  )
}

export default Layout