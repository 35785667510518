// i18n.js
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import ruTranslation from './locales/ru/translate.json';
import kzTranslation from './locales/kz/translate.json'

const resources = {
  ru: { translation: ruTranslation },
  kz: {translation: kzTranslation}
};

i18n
  .use(initReactI18next)
  .init({
    resources,
    lng: 'ru', // default language
    fallbackLng: 'ru',
    interpolation: {
      escapeValue: false, // react already escapes by default
    },
  });

export default i18n;
