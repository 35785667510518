import React from 'react'
import prof from '../assets/icons/prof.svg'
import individual from '../assets/icons/indiv.svg'
import modern from '../assets/icons/modern.svg'
import Gallery from '../components/Gallery'
import QuestionsForm from '../components/QuestionsForm'
import { useTranslation } from 'react-i18next'

const About = () => {
    const {t} = useTranslation()

    const array = t('about.array', {returnObjects: true})

    const icons = [prof, individual, modern]

    return (
        <div className='flex flex-col'>
            <div className='container mx-auto mt-20'>
                <div className='flex flex-col items-center space-y-8'>
                    <div className='flex flex-col items-center space-y-4'>
                        <div className='text-gray-400 font-bold text-2xl md:text-3xl'>{t('about.title')}</div>
                        <div className='text-darkBlue font-bold text-2xl md:text-3xl text-center'>
                            {t('about.description')}
                        </div>
                    </div>
                </div>
                <div className='grid grid-cols-1 lg:grid-cols-2 gap-4 lg:gap-16 mt-12'>
                    <div className='flex flex-col space-y-4 md:space-y-6'>
                        <div className='relative flex items-center justify-center border-4 sm:border-none border-darkBlue border-about rounded-[15px] py-4 md:py-5 px-8 md:px-10'>
                            <span className='text-gray-700 md:text-lg'>
                                {t('about.p-border')}
                            </span>
                            <div className='md:hidden absolute -right-[5px] top-1/5'>
                                <div className='bg-light w-6 h-24 rounded-full'>

                                </div>
                            </div>
                            <div className='md:hidden absolute -left-[5px] top-1/5'>
                                <div className='bg-light w-6 h-24 rounded-full'>

                                </div>
                            </div>
                        </div>
                        <p className='text-gray-700 md:text-lg'>{t('about.p1')}</p>
                        <p className='text-gray-700 md:text-lg'>
                        {t('about.p2')}
                        </p>
                        <p className='text-gray-700 md:text-lg'>
                        {t('about.p3')}
                        </p>
                    </div>
                    <div className='flex flex-col space-y-4 md:space-y-6'>
                        <p className='text-gray-700 md:text-lg'>{t('about.p4')}</p>
                        <p className='text-gray-700 md:text-lg'>
                        {t('about.p5')}
                        </p>
                        <p className='text-gray-700 md:text-lg'>
                        {t('about.p6')}
                        </p>
                    </div>
                </div>
            </div>
            <div className='bg-hero-rect py-20 mt-20'>
                <div className='container mx-auto'>
                    <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8'>
                        {
                            array.map((item, id) => (
                                <div className='flex flex-col justify-center items-center'>
                                    <img src={icons[id]} />
                                    <div className='text-white font-semibold text-2xl mt-6 text-center'>{item.title}</div>
                                    <p className='text-white text-lg text-center mt-4'>
                                    {item.text}
                                    </p>
                                </div>
                            ))
                        }
                        
                    </div>
                </div>
            </div>
            <Gallery/>
            <QuestionsForm/>
        </div>
    )
}

export default About