import React from 'react'
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

import photo1 from '../assets/imgs/gallery/1.png'
import photo2 from '../assets/imgs/gallery/2.png'
import photo3 from '../assets/imgs/gallery/3.png'
import photo4 from '../assets/imgs/gallery/4.png'
import photo5 from '../assets/imgs/gallery/5.png'
import photo6 from '../assets/imgs/gallery/6.png'
import photo7 from '../assets/imgs/gallery/7.png'
import photo8 from '../assets/imgs/gallery/8.png'
import CustomDot from './CustomDots';
import CustomSlider from './CustomSlider';
import { useTranslation } from 'react-i18next';

const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
      slidesToSlide: 1
    },
    tablet: {
        breakpoint: { max: 1024, min: 768 },
        items: 3,
        slidesToSlide: 1
    },
    mobile: {
        breakpoint: { max: 768, min: 300 },
        items: 1,
        slidesToSlide: 1
    },
};

const images = [
    photo1, photo2, photo3, photo4, photo5, photo6, photo7, photo8
]

const Gallery = () => {

    const {t} = useTranslation()

    return (
        <div className='flex flex-col space-y-8 mt-20'>
            <div className='text-darkBlue font-bold text-3xl text-center'>
                {t('gallery')}
            </div>
            <div className='relative'>
                <div className='hidden lg:block absolute top-0 -left-2 h-full w-[100px] bg-white z-30 blur-2xl'>
                    
                </div>
                <div className='hidden lg:block absolute top-0 -right-2 h-full w-[100px] bg-white z-30 blur-2xl'>
                    
                </div>
                <Carousel
                    responsive={responsive}
                    swipeable={true}
                    draggable={true}
                    showDots={true}
                    autoPlay={true}
                    removeArrowOnDeviceType={["desktop"]}
                    customDot={<CustomSlider max={7}/>}
                    infinite={true}
                    >
                        {
                            images.map((item, id) => (
                                <div key={id} className='ml-4 lg:ml-0 mr-4 mb-8'>
                                    <img draggable={false} className='aspect-square object-cover w-full h-full rounded-[30px]' src={item} key={id} />
                                </div>
                            ))
                        }
                    </Carousel>
            </div>
            
        </div>
    )
}

export default Gallery