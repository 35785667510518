import React from 'react'
import QuestionsForm from '../components/QuestionsForm';
import { Trans, useTranslation } from 'react-i18next';
  
const Services = () => {

    const {t} = useTranslation()

    const medicalProcedures = t('services.mra', {returnObjects: true})
    const ctProcedures = t('services.ct', {returnObjects: true})

    return (
        <div className='flex flex-col'>
            <div className='container mx-auto mt-20'>
                <div className='flex flex-col'>
                    <div className='flex flex-col items-center space-y-4 px-4 lg:px-0'>
                        <div className='font-bold text-center text-darkBlue lg:text-gray-400 text-3xl'>{t('services.title')}</div>
                        <div className='lg:hidden text-center'>
                            <Trans
                                i18nKey={'services.description-mob'}
                                components={{1: <span className='font-semibold' />}}
                            />
                        
                        </div>
                        <div className='hidden lg:block text-darkBlue font-bold text-3xl text-center'>
                        {t('services.description')}
                        </div>
                    </div>
                </div>
            </div>
            <div className='container mx-auto font-bold text-2xl mt-20 uppercase'>
                {t('services.mra-title')}
            </div>
            <div className='flex flex-col mt-8'>
                {
                    medicalProcedures.map((item, id) => (
                        <div key={id} className={`${id % 2 !== 0 ? 'bg-transparent' : 'bg-lightBlue'} py-4 w-full`}>
                            <div className='container mx-auto font-semibold'>
                                {item}
                            </div>
                        </div>
                    ))
                } 
            </div>
            <div className='container mx-auto font-bold text-2xl mt-20 uppercase'>
                {t('services.ct-title')}
            </div>
            <div className='flex flex-col mt-8'>
                {
                    ctProcedures.map((item, id) => (
                        <div key={id} className={`${id % 2 !== 0 ? 'bg-transparent' : 'bg-lightBlue'} py-4 w-full`}>
                            <div className='container mx-auto font-semibold'>
                                {item}
                            </div>
                        </div>
                    ))
                } 
                <div className='bg-lightBlue py-3 w-full'>
                    <div className='container mx-auto font-bold text-xl uppercase'>
                        Денситометрия
                    </div>
                </div>
            </div>
            <QuestionsForm/>
        </div>
    )
}

export default Services