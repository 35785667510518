import React from 'react'

const CustomSlider = ({active, onClick, index, max}) => {
    return (
        <div className={`flex bg-gray-300 h-3 ${index === 0 && 'rounded-l-full'} ${index === max && 'rounded-r-full'}`}>
            <button
                className={`w-12 h-full ${active ? 'bg-darkBlue rounded-full' : 'bg-gray-300'} ${index === 0 && 'rounded-l-full'} ${index === max && 'rounded-r-full'}`}
                onClick={() => onClick()}
            />
        </div>
        
    )
}

export default CustomSlider