import { type } from '@testing-library/user-event/dist/type'
import React, {useState} from 'react'
import { useTranslation } from 'react-i18next'

const QuestionsForm = () => {
    const {t} = useTranslation()
    const [formState, setFormState] = useState({
        name: "",
        phone: "",
        type: "Консультация"
    })

    const handleChange = (e) => {
        const { name, value } = e.target;
        if (value.length <= 150) {
            setFormState((prevState) => ({
                ...prevState,
                [name]: value
            }));
        }
    };

    const submit = async (e) => {
        e.preventDefault()
    
        const request = await fetch("https://mail-server-xi.vercel.app/mail-mgm", {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(formState)
        })
        
        const res = await request.json()
        alert(res.message)

        setFormState({
            name: "",
            phone: "",
            type: "Консультация"
        })
    }

    return (
        <div id='questions' className='bg-questions mt-20'>
            <div className='container mx-auto py-20'>
                <div className='flex flex-col max-w-2xl'>
                    <div className='text-2xl md:text-4xl text-center md:text-left font-bold'>{t('questions.title')}</div>
                    <div className='text-2xl md:text-4xl text-center md:text-left font-bold mt-1'>{t('questions.title1')}</div>
                    <p className='text-gray-600 text-xl mt-5 max-w-xl text-center md:text-left '>{t('questions.desc')}</p>
                    <form className='grid grid-cols-1 md:grid-cols-2 gap-6 mt-8' onSubmit={submit}>
                        <input
                            id='name'
                            name='name'
                            type='text'
                            value={formState.name}
                            onChange={handleChange}
                            className='bg-white shadow rounded-[30px] p-6 text-gray-600 outline-none focus:ring-0'
                            placeholder={t('questions.name')}
                            required
                        />
                        <input
                            id='phone'
                            name='phone'
                            type='tel'
                            value={formState.phone}
                            onChange={handleChange}
                            className='bg-white shadow rounded-[30px] p-6 text-gray-600 outline-none focus:ring-0'
                            placeholder={t('questions.phone')}
                            required
                        />
                        <button type='submit' className='bg-darkBlue px-4 py-6 text-white font-semibold rounded-[30px] hover:shadow-xl'>
                            {t('questions.submit')}
                        </button>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default QuestionsForm