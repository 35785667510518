import React from 'react'
import { Link } from 'react-router-dom'
import Doctors from '../components/Doctors'
import team from '../assets/imgs/team-care.png'
import Gallery from '../components/Gallery'
import QuestionsForm from '../components/QuestionsForm'
import Licenses from '../components/Licenses'
import { useTranslation } from 'react-i18next'

const Team = () => {

    const {t} = useTranslation()

    return (
        <div className='flex flex-col'>
            <div className='bg-team'>
                <div className='relative container mx-auto'>
                    <div className='flex items-center'>
                        <div className='flex flex-col space-y-6 md:space-y-8 py-20 md:py-44'>
                            <div className='font-bold text-2xl md:text-4xl max-w-2xl'>
                                {t('team.title')}
                            </div>
                            <p className='md:text-xl max-w-lg'>
                                {t('team.description')}
                            </p>
                            <div className='flex justify-start'>
                                <a href='#questions' className='flex justify-center items-center text-light font-medium px-8 py-4 md:px-12 md:py-5 md:text-lg rounded-[20px] md:rounded-[30px] shadow-xl hover:scale-105 bg-darkBlue'>
                                    {t('home.sign-up')}
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Doctors/>
            <div className='bg-gradient-light py-10 md:py-20 mt-20'>
                <div className='container mx-auto'>
                    <div className='grid grid-cols-1 lg:grid-cols-2 gap-12'>
                        <div className='hidden lg:flex flex-col'>
                            <img src={team} className='object-cover' /> 
                        </div>
                        <div className='flex flex-col flex-grow space-y-6'>
                            <div className='text-darkBlue text-2xl font-bold'>
                                {t('team.p')}
                            </div>
                            <p className='xl:text-lg'>
                            {t('team.p1')}
                            </p>
                            <p className='xl:text-lg'>
                            {t('team.p2')}
                            </p>
                            <p className='xl:text-lg'>
                            {t('team.p3')}
                            </p>
                            <p className='xl:text-lg'>
                            {t('team.p4')}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <Gallery/>
            <QuestionsForm/>
        </div>
    )
}

export default Team