import React, {useState} from 'react'
import media from '../assets/icons/media.svg'
import ymaps from 'ymaps';
import { useTranslation } from 'react-i18next';

const Contact = () => {
    const {t} = useTranslation()

    const [formState, setFormState] = useState({
        name: "",
        phone: "",
        message: "",
        type: "Сообщение"
    })

    const handleChange = (e) => {
        const { name, value } = e.target;
        if (value.length <= 150) {
            setFormState((prevState) => ({
                ...prevState,
                [name]: value
            }));
        }
    };

    const submit = async (e) => {
        e.preventDefault()
    
        const request = await fetch(
            "https://mail-server-xi.vercel.app/mail-mgm",
            {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(formState)
        })
        
        const res = await request.json()
        alert(res.message)

        setFormState({
            name: "",
            phone: "",
            message: "",
            type: "Сообщение"
        })
    }

    ymaps
        .load("https://api-maps.yandex.ru/2.1/?apikey=3ccce595-960c-4919-ad3d-65478a41ba4a&lang=ru_RU")
        .then(maps => {
            let map = new maps.Map('map', {
                center: [43.236232, 76.850922],
                zoom: 18
            });

            map.geoObjects.add(new maps.Placemark([43.236232, 76.850922], {
                    balloonContent: '<strong>MGM Stan</strong>'
                }, {
                    preset: 'islands#icon',
                    iconColor: '#0095b6'
                })
            )
        })
        .catch(error => console.log('Failed to load Yandex Maps', error));


    return (
        <div className='container mx-auto mt-16'>
            <div className='flex flex-col space-y-8 md:space-y-16'>
                <div className='flex justify-center text-3xl font-bold text-darkBlue'>{t('contact.title')}</div>
                <div className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 lg:grid-cols-5 gap-8'>
                    <div className='flex flex-col space-y-4 items-center sm:items-start'>
                        <div className='font-semibold text-2xl'>Email:</div>
                        <div className='flex flex-col space-y-2 text-lg items-center sm:items-start'>
                            <div>mgm.stan@mail.ru</div>
                            <div className='hidden sm:block'>mgm-shymkent@bk.ru </div>
                        </div>
                    </div>
                    <div className='flex flex-col space-y-4 items-center sm:items-start'>
                        <div className='font-semibold text-2xl'>{t('contact.phone')}</div>
                        <div className='flex flex-col space-y-2 text-lg items-center sm:items-start'>
                            <div>+7 727 3 555 000</div>
                            <div>+7 776 3 555 000</div>
                            <div>+7 778 3 555 000</div>
                            <div>+7 707 3 555 000</div>
                        </div>
                    </div>
                    <div className='flex flex-col space-y-4 items-center sm:items-start'>
                        <div className='font-semibold text-2xl'>WhatsApp:</div>
                        <div className='flex flex-col space-y-2 text-lg items-center sm:items-start'>
                            <div>+7 707 3 555 000</div>
                        </div>
                    </div>
                    <div className='flex flex-col space-y-4 items-center sm:items-start'>
                        <div className='font-semibold text-2xl'>{t('contact.media')}</div>
                        <div className='flex flex-col space-y-2 items-center sm:items-start'>
                            <div className='flex items-center space-x-1 text-lg'>
                                <img src={media} />
                                <span className='-mt-1'>mgmstan.almaty</span>
                            </div>
                            <div className='flex items-center space-x-1 text-lg'>
                                <img src={media} />
                                <span className='-mt-1'>mgmstan.shymkent</span>
                            </div>
                        </div>
                    </div>
                    <div className='order-1 flex flex-col space-y-4 items-center sm:items-start'>
                        <div className='font-semibold text-2xl'>{t('contact.work')}</div>
                        <div className='flex flex-col space-y-2 items-center sm:items-start'>
                            <div className='text-lg'>{t('contact.everyday')}</div>
                        </div>
                    </div>
                </div>
                <div className='grid grid-cols-1 md:grid-cols-2 justify-between gap-20'>
                    <form className='flex flex-col space-y-8' onSubmit={submit}> 
                        <input
                            id='name'
                            name='name'
                            type='text'
                            value={formState.name}
                            onChange={handleChange}
                            className='px-8 py-6 bg-transparent rounded-[20px] border-2 border-darkBlue text-lg focus:ring-0 outline-none'
                            placeholder={t('contact.name')}
                            required
                        />
                        <input
                            id='phone'
                            name='phone'
                            type='tel'
                            value={formState.phone}
                            onChange={handleChange}
                            className='px-8 py-6 bg-transparent rounded-[20px] border-2 border-darkBlue text-lg focus:ring-0 outline-none'
                            placeholder={t('contact.telephone')}
                            required
                        />
                        <textarea
                            id='message'
                            name='message'
                            value={formState.message}
                            onChange={handleChange}
                            rows={7}
                            className='px-8 py-6 bg-transparent rounded-[20px] border-2 border-darkBlue text-lg focus:ring-0 outline-none'                            
                            placeholder={t('contact.message')}
                            required
                        >
                        </textarea>
                        <button type='submit' className='bg-darkBlue px-4 py-6 text-white font-semibold rounded-[20px] hover:shadow-xl'>
                            {t('contact.call-us')}
                        </button>
                    </form>
                    <div className='hidden md:block relative'>
                        <div className='absolute bottom-0 left-0 w-[90%] h-[90%] rounded-[20px] z-20'>
                            <div id='map' className='w-full h-full'></div>    
                        </div>
                        <div className='absolute top-0 right-0 w-[90%] h-[90%] bg-darkBlue rounded-[20px]'>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Contact