import React, {useState} from 'react'
import logo from '../assets/imgs/logo.png'
import { Link } from 'react-router-dom'
import close from '../assets/icons/close.svg'
import { useTranslation } from 'react-i18next'

const Navbar = () => {
    const [mobileMenu, setMenu] = useState(false)

    const {t, i18n} = useTranslation()

    const changeLanguage = (lang) => {
        i18n.changeLanguage(lang)
        setMenu(false)
    }

    return (
        <header className='fixed top-0 left-0 w-[100vw] z-50'>
            <nav className='flex flex-col bg-light'>
            <div className='container mx-auto'>
                <div className='flex items-center justify-between py-4'>
                    <Link to={"/"} className='flex'>
                        <img src={logo} className='w-[120px] md:w-[160px]' />
                    </Link>
                    <div className='hidden lg:flex flex-col space-y-1'>
                        <div className='text-gray-500 text-sm'>{t('navbar.address-t')}</div>
                        <div className='font-semibold'>{t('navbar.address')}</div>
                    </div>
                    <div className='hidden lg:flex flex-col space-y-1'>
                        <div className='text-gray-500 text-sm'>{t('navbar.email-t')}</div>
                        <a href='mailto:mgm.stan@mail.ru' className='font-semibold'>{t('navbar.email')}</a>
                    </div>
                    <div className='hidden lg:flex flex-col space-y-1'>
                        <div className='text-gray-500 text-sm'>{t('navbar.work-t')}</div>
                        <div className='font-semibold'>{t('navbar.work')}</div>
                    </div>
                    <div className='hidden lg:flex flex-col space-y-1'>
                        <a href='tel:+77273555000' className='font-semibold'>+7 727 3 555 000</a>
                        <a href='tel:+77763555000' className='font-semibold'>+7 776 3 555 000</a>
                    </div>
                    <div className='hidden lg:flex flex-col space-y-1'>
                        <div onClick={() => changeLanguage("kz")} className='hover:text-blue-500 hover:cursor-pointer'>KZ</div>
                        <div onClick={() => changeLanguage("ru")} className='hover:text-blue-500 hover:cursor-pointer'>RU</div>
                    </div>
                    <div className='lg:hidden flex flex-col space-y-2' onClick={() => setMenu(true)}>
                        <div className='w-12 h-[1.5px] bg-darkBlue'></div>
                        <div className='w-8 h-[1.5px] bg-darkBlue ml-auto'></div>
                        <div className='w-12 h-[1.5px] bg-darkBlue'></div>
                    </div>
                </div>
            </div>
            <div className='hidden lg:block bg-lightGray'>
                <div className='container mx-auto py-6'>
                    <div className='flex items-center justify-between'>
                        <Link to={"/about"} className='font-semibold text-lg'>{t('navbar.about')}</Link>
                        <Link to={"/team"} className='font-semibold text-lg'>{t('navbar.team')}</Link>
                        <Link to={"/services"} className='font-semibold text-lg'>{t('navbar.services')}</Link>
                        <Link to={"/contact"} className='font-semibold text-lg'>{t('navbar.contact')}</Link>
                        <Link to={"/branches"} className='font-semibold text-lg'>{t('navbar.branches')}</Link>
                    </div>
                </div>
            </div>
            <div className={`fixed top-0 left-0 z-50 w-[100vw] bg-white transition-all ease-in-out duration-500 ${mobileMenu ? 'h-[100vh]' : 'h-[0px]'} overflow-hidden`}>
                <div className='flex flex-col p-6 mt-2 w-full h-full'>
                    <div className='flex justify-end hover:cursor-pointer'>
                        <img onClick={() => setMenu(false)} src={close} />
                    </div>
                    <div className='flex flex-col space-y-10 mt-16'>
                        <Link onClick={() => setMenu(false)} to={"/about"} className='font-semibold text-lg'>{t('navbar.about')}</Link>
                        <Link onClick={() => setMenu(false)} to={"/team"} className='font-semibold text-lg'>{t('navbar.team')}</Link>
                        <Link onClick={() => setMenu(false)} to={"/services"} className='font-semibold text-lg'>{t('navbar.services')}</Link>
                        <Link onClick={() => setMenu(false)} to={"/contact"} className='font-semibold text-lg'>{t('navbar.contact')}</Link>
                        <Link onClick={() => setMenu(false)} to={"/branches"} className='font-semibold text-lg'>{t('navbar.branches')}</Link>
                    </div>
                    <div className='flex items-center justify-start space-x-6 mt-10'>
                        <div onClick={() => changeLanguage("kz")} className='hover:text-blue-500 hover:cursor-pointer font-medium text-2xl'>KZ</div>
                        <div onClick={() => changeLanguage("ru")} className='hover:text-blue-500 hover:cursor-pointer font-medium text-2xl'>RU</div>
                    </div>
                </div>
            </div>
            </nav>
        </header>
    )
}

export default Navbar