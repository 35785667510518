import React from 'react'
import { Link } from 'react-router-dom'
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import flags from '../assets/imgs/flags.png'
import bookmark from '../assets/icons/bookmark.svg'
import service1 from '../assets/imgs/services/service1.png'
import service2 from '../assets/imgs/services/service2.png'
import service3 from '../assets/imgs/services/service3.png'
import service4 from '../assets/imgs/services/service4.png'
import service5 from '../assets/imgs/services/service5.png'

import sales1 from '../assets/imgs/sales/sales1.png'
import sales2 from '../assets/imgs/sales/sales2.png'
import sales3 from '../assets/imgs/sales/sales3.png'
import backdrop from '../assets/imgs/backdrop.png'


import Gallery from '../components/Gallery';
import Doctors from '../components/Doctors';
import CustomDot from '../components/CustomDots';
import QuestionsForm from '../components/QuestionsForm';
import CustomSlider from '../components/CustomSlider';
import { useTranslation } from 'react-i18next';

const services = [
    service1, service2, service3, service4, service5
]

const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 2,
      slidesToSlide: 1 // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 768 },
      items: 2,
      slidesToSlide: 1 // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 768, min: 300 },
      items: 1,
      slidesToSlide: 1 // optional, default to 1.
    }
};

const responsive_reviews = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
      slidesToSlide: 1 // optional, default to 1.
    },
    tablet: {
        breakpoint: { max: 1024, min: 768 },
        items: 2,
        slidesToSlide: 1 // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 768, min: 300 },
      items: 1,
      slidesToSlide: 1 // optional, default to 1.
    }
};


const sales = [
    sales1, sales2, sales3
]


const Home = () => {
    const {t} = useTranslation()

    const array = t('home.array', {returnObjects: true})
    const services_array = t('home.services-array', {returnObjects: true})
    const sales_array = t('home.sales-array', {returnObjects: true})
    const reviews = t('reviews.array', {returnObjects: true})

    return (
        <div className='flex flex-col overflow-x-hidden'>
            <div className='bg-hero'>
                <div className='relative container mx-auto'>
                    <div className='flex items-center'>
                        <div className='relative flex flex-col space-y-4 md:space-y-8 pt-10 md:py-44'>
                            <div className='absolute top-0 left-0 md:left-[15%] w-full h-full'>
                                <img className='w-full h-full' src={backdrop} />
                            </div>
                            <div className='font-bold text-2xl md:text-5xl max-w-4xl text-center md:text-left z-10'>
                                {t('home.title1')}
                                <span className='text-black md:text-darkBlue'>{t('home.title2')}</span>
                            </div>
                            
                            <p className='text-sm md:text-xl max-w-2xl z-10 text-center md:text-left px-6 md:px-0'>
                                {t('home.description')}
                            </p>
                            <div className='flex justify-center md:justify-start'>
                                <a href='#questions' className='flex justify-center items-center text-light font-medium px-8 py-4 text-lg rounded-[20px] md:px-12 md:py-5 md:rounded-[30px] shadow-xl hover:scale-105 bg-darkBlue z-20'>
                                    {t('home.sign-up')}
                                </a>
                            </div>
                            <div className='flex justify-center z-20'>
                                <img src={flags} className='md:hidden w-[500px]'/>
                            </div>
                        </div>
                    </div>
                    <div className='hidden md:block absolute -bottom-[10%] -right-[15%]'>
                        <img src={flags} className='w-[700px] xl:w-[900px]'/>
                    </div>
                </div>
            </div>
            <div className='container mx-auto mt-20 lg:-mt-24 z-20'>
                <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 lg:gap-0'>
                    {
                        array.map((item, id) => (
                            <div className={`bg-hero-rect rounded-[30px] ${id === 0 && 'lg:rounded-r-[0px]'} ${id === 3 && 'lg:rounded-l-[0px]'} ${id !== 0 && id !== 3 && 'lg:rounded-[0px]'}`}>
                                <div className='flex flex-col text-light  items-center pb-3'>
                                    <img src={bookmark} width={30} className='mb-6'/>
                                    <div className='font-semibold text-lg px-4 mb-3'>{item.title}</div>
                                    <div className='text-center px-4'>
                                    {item.desc}
                                    </div>
                                </div>
                            </div>
                        ))
                    }
                </div>
            </div>
            <div className='container mx-auto mt-20'>
                <div className='flex flex-col items-center space-y-8'>
                    <div className='flex flex-col items-center space-y-4'>
                        <div className='text-gray-400 font-bold text-3xl'>{t('home.services-title')}</div>
                        <div className='text-darkBlue font-bold text-3xl text-center'>
                            {t('home.services-desc')}
                        </div>
                    </div>
                    <div className='flex gap-6 flex-wrap lg:justify-center'>
                        {
                            services_array.map((item, id) => (
                                <div key={item.title} className='bg-white rounded-[30px] p-4 md:w-1/2 lg:w-[30%] hover:shadow-xl hover:cursor-pointer'>
                                    <div className='flex flex-col space-y-3 h-full'>
                                        <div className='flex flex-col space-y-3 h-[90%]'>
                                            <img src={services[id]} />
                                            <div className='text-darkBlue font-semibold text-xl'>{item.title}</div>
                                            <p className='text-sm'>{item.desc}</p>
                                        </div>
                                        <div className='flex flex-col space-y-4'>
                                            <div className='text-darkBlue text-2xl font-semibold'>{item.price}</div>
                                            <div className='flex justify-between space-x-3'>
                                                <Link to={"/services"} className='flex items-center justify-center font-semibold border-2 border-darkBlue py-3 rounded-[20px] w-1/2'>
                                                    {t('home.more')}
                                                </Link>
                                                <a href='#questions' className='flex items-center justify-center font-semibold text-light bg-hero-rect py-3 rounded-[20px] w-1/2 hover:shadow-xl'>
                                                    {t('home.sign-up')}
                                                </a>
                                            </div>

                                        </div>

                                    </div> 
                                </div>
                            ))
                        }
                        
                    </div>
                </div>
            </div>
            <div className='flex flex-col'>
                <div className='container mx-auto mt-20'>
                    <div className='flex flex-col items-center space-y-8'>
                        <div className='flex flex-col items-center space-y-4'>
                            <div className='text-gray-400 font-bold text-3xl'>{t('home.sales-title')}</div>
                            <div className='text-darkBlue font-bold text-3xl text-center'>
                            {t('home.sales-desc')}
                            </div>
                        </div>
                    </div>
                    
                </div>
                <Carousel
                    containerClass={`container mx-auto p-4 mt-8`}
                    responsive={responsive}
                    autoPlay={true}
                    swipeable={true}
                    draggable={true}
                    showDots={false}
                    infinite={true}
                    >
                        {
                            sales_array.map((item, id) => (
                                <div key={item} className='rounded-[30px] bg-white shadow mr-8 h-full max-w-[400px] md:max-w-full overflow-hidden'>
                                    <div className='flex flex-col'>
                                        <img src={sales[id]} className='rounded-t-[30px] h-[250px] md:h-[300px] lg:h-[380px]' alt=''/>
                                        <div className='relative flex items-center p-4 md:space-x-4'>
                                            <div className='absolute -top-2 left-0 w-full'>
                                                <div className='bg-white blur-sm w-full h-[20px]'>

                                                </div>
                                            </div>
                                            <p className='font-semibold lg:text-2xl'>{item}</p>
                                            <a href='#questions' className='flex justify-center items-center text-light font-medium px-8 py-4 rounded-[20px] shadow-xl hover:scale-105 bg-hero-rect'>
                                                {t('home.sign-up')}
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            ))
                        }
                    </Carousel>
            </div>
            <Doctors/>
            <Gallery/>
            <div className='container mx-auto mt-20'>
                <div className='flex flex-col items-center space-y-8'>
                    <div className='flex flex-col items-center space-y-4'>
                        <div className='text-gray-400 font-bold text-3xl'>{t('reviews.title')}</div>
                        <div className='text-darkBlue font-bold text-3xl text-center'>
                        {t('reviews.desc')}
                        </div>
                    </div>
                    <Carousel
                        containerClass={'w-full'}
                        responsive={responsive_reviews}
                        swipeable={true}
                        draggable={true}
                        showDots={true}
                        autoPlay={true}
                        infinite
                        arrows={false}
                        customDot={<CustomSlider max={2}/>}
                        itemClass="carousel-item"
                    >
                        {
                            reviews.map(item => (
                                <div key={item.name} className='bg-white shadow-md rounded-[20px] py-3 max-w-[390px] md:max-w-[500px] mr-0 md:mr-8 mb-6'>
                                    <div className='flex flex-col'>
                                        <div className='flex items-center justify-between py-5 border-b border-gray-300 px-6'>
                                            <div className='text-xl font-semibold text-darkBlue'>{item.name}</div>
                                            <div className='text-gray-500 text-xl'>{item.date}</div>
                                        </div>
                                        <p className='px-6 py-5'>
                                            {item.text}
                                        </p>
                                    </div>
                                </div>
                            ))
                        }
                    </Carousel>
                </div>
            </div>
            <QuestionsForm/>
        </div>
    )
}

export default Home