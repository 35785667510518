import React from 'react'
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

import doctor1 from '../assets/imgs/doctors/doctor1.png'
import doctor2 from '../assets/imgs/doctors/doctor2.png'
import doctor3 from '../assets/imgs/doctors/doctor3.png'
import doctor4 from '../assets/imgs/doctors/doctor4.png'
import CustomDot from './CustomDots';
import { ArrowLeft, ArrowRight, ButtonGroup } from './CustomArrows';
import CustomSlider from './CustomSlider';
import { useTranslation } from 'react-i18next';

const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
      slidesToSlide: 1 // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 768 },
      items: 3,
      slidesToSlide: 1 // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 800, min: 300 },
      items: 1,
      slidesToSlide: 1 // optional, default to 1.
    }
};


const doctors = [
    doctor1, doctor2, doctor3, doctor4
]

const Doctors = () => {
    const {t} = useTranslation()

    const array = t('doctors.array', {returnObjects: true})

    return (
        <div className='flex flex-col'>
            <div className='container mx-auto mt-20'>
                <div className='flex flex-col items-center space-y-8'>
                    <div className='flex flex-col items-center space-y-4'>
                        <div className='text-gray-400 font-bold text-3xl'>{t('doctors.title')}</div>
                        <div className='text-darkBlue font-bold text-3xl text-center'>
                        {t('doctors.desc')}
                        </div>
                    </div>
                    <div className='lg:hidden grid grid-cols-1 md:grid-cols-2 gap-4'>
                {
                    array.map((item, id) => (
                        <div key={item.name} className='rounded-[30px] bg-white shadow p-6 mb-4'>
                            <div className='flex flex-col'>
                                <div className='relative'>
                                    <div className='bg-hero-rect w-full h-[270px] rounded-[20px]'></div>
                                    <img draggable={false} src={doctors[id]} className='absolute bottom-0 left-0 rounded-[30px] h-[290px]' alt=''/>
                                </div>
                            
                                <div className='flex flex-col space-y-4 mt-4'>
                                    <p className='font-semibold text-xl text-darkBlue'>{item.name}</p>
                                    <p>{item.occupation}</p>
                                </div>
                            </div>
                        </div>
                    ))
                }
            </div>
                </div>
            </div>
            
            <Carousel
                containerClass={`hidden lg:flex container mx-auto p-4 mt-8`}
                responsive={responsive}
                swipeable={true}
                draggable={true}
                showDots={true}
                arrows={false}
                autoPlay={true}
                customDot={<CustomSlider max={3}/>}
                infinite={true}
                >
                    {
                        array.map((item, id) => (
                            <div key={item.name} className='rounded-[30px] bg-white shadow mr-8 p-6 mb-4 hover:shadow-xl'>
                                <div className='flex flex-col'>
                                    <div className='relative'>
                                        <div className='bg-hero-rect w-full h-[270px] rounded-[20px]'></div>
                                        <img draggable={false} src={doctors[id]} className='absolute bottom-0 left-0 rounded-[30px] h-[300px]' alt=''/>
                                    </div>
                                    
                                    <div className='flex flex-col space-y-2 mt-4 mb-4'>
                                        <p className='font-semibold text-xl text-darkBlue'>{item.name}</p>
                                        <p className='text-gray-600'>{item.occupation}</p>
                                    </div>
                                </div>
                            </div>
                        ))
                    }
                </Carousel>
        </div>
    )
}

export default Doctors